.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.with-sidebar {
  padding-left: 240px;
  margin-left: 240px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navDropDown {
  color: rgba(4, 125, 149, 1);
  background-color: rgba(4, 125, 149, 1);
}

.img-hover {
  cursor: pointer;
}

.img-hover {
  cursor: pointer;
}

.home-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.mobile-home-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.icon-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 35px;
  margin-top: 20px;
}

.minor-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 40px;
  margin-top: 20px;
}

.minor-mobile-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  margin-top: 20px;
}

.header-img {
  align-items: center;
}

.tab-elements {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.spotlight-background {
  background: linear-gradient(180deg,
      rgba(45, 62, 255, 0.8) 0%,
      rgba(58, 125, 188, 0.8) 24.13%,
      rgba(66, 163, 147, 0.8) 54.17%,
      rgba(91, 154, 168, 0.8) 75.17%,
      rgba(169, 124, 236, 0.8) 100%);
  height: 405px;
  padding-left: 7%;
  padding-right: 7%;
}

.popular-deal-title {
  text-align: left;
  font-size: 25px;
  font-weight: 600;

  line-height: 22px;
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 0;
  overflow-x: hidden;
}

.popular-deal-small-title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;

  line-height: 22px;
  margin-top: 20px;
  margin-right: 0;
  overflow-x: hidden;
}

.popular-deal-title-img {
  width: 33px;
  height: 39px;
  top: 170px;
  left: 86px;
  margin-right: 20px;
}

.recent-deal-title {
  text-align: left;
  font-size: 25px;
  font-weight: 600;

  line-height: 22px;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.filter-font {
  font-size: 16px;
}

.comment-tab-title {
  text-align: left;
  font-size: 25px;
  font-weight: 600;

  line-height: 22px;
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 0;
  overflow-x: hidden;
}

.img-hover {
  cursor: pointer;
}

.home-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.mobile-home-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.icon-title {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-left: 40px;
  margin-bottom: 35px;
  margin-top: 20px;
}

.minor-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 40px;
  margin-top: 20px;
}

.minor-mobile-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  margin-top: 20px;
}

.header-img {
  align-items: center;
}

.tab-elements {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.spotlight-background {
  background: linear-gradient(180deg,
      rgba(45, 62, 255, 0.8) 0%,
      rgba(58, 125, 188, 0.8) 24.13%,
      rgba(66, 163, 147, 0.8) 54.17%,
      rgba(91, 154, 168, 0.8) 75.17%,
      rgba(169, 124, 236, 0.8) 100%);
  height: 375px;
  padding-left: 7%;
  padding-right: 7%;
}

.popular-deal-title {
  text-align: left;
  font-size: 25px;
  font-weight: 600;

  line-height: 22px;
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 0;
  overflow-x: hidden;
}

.popular-deal-small-title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;

  line-height: 22px;
  margin-top: 20px;
  margin-right: 0;
  overflow-x: hidden;
}

.popular-deal-title-img {
  width: 33px;
  height: 39px;
  top: 170px;
  left: 86px;
  margin-right: 20px;
}

.popular-deal-title-img-new {
  font-size: 25px;
  font-weight: 600;
}

.recent-deal-title {
  text-align: left;
  font-size: 25px;
  font-weight: 600;

  line-height: 22px;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.filter-font {
  font-size: 16px;
}

.comment-tab-title {
  text-align: left;
  font-size: 25px;
  font-weight: 600;

  line-height: 22px;
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 0;
  overflow-x: hidden;
}

.tw-h-29-icon {
  height: 7.36rem;
}

.tw-h-\[330px\] {
  height: 330px;
}

.tw-h-\[50vh\] {
  height: 50vh;
}

.tw-w-29-icon {
  width: 7.36rem;
}

.carousel-indicators {
  /*position: relative !important;*/
  bottom: -50px !important;
}

.tw-top-12 {
  top: 3rem;
}

/*

.carousel-control-prev-icon {
  height: 100px;
  width: 100px;
  outline: rgba(255, 255, 255, 1);
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background: rgb(105, 105, 105);
  padding: 30px;
}

.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: rgba(255, 255, 255, 1);
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background: rgb(105, 105, 105);
  padding: 30px;
}

*/
.carousel-control-prev-icon {
  /* height: 100px; */
  /* width: 100px; */
  /* outline: rgba(255, 255, 255, 1); */
  /* background-size: 100%, 100%; */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none"><g filter="url(%23filter0_d_14559_22965)"><circle cx="39" cy="35" r="34.5" fill="%23DEDEDE" fill-opacity="0.3" stroke="white"/><path d="M48.1461 50.449L45.2118 53.6497L27.7633 37.6739C27.4821 37.418 27.2543 37.109 27.093 36.7647C26.9317 36.4204 26.8401 36.0476 26.8234 35.6678C26.8067 35.2879 26.8654 34.9085 26.9959 34.5514C27.1264 34.1943 27.3263 33.8665 27.584 33.587L43.5654 16.1353L46.7659 19.0667L31.7662 35.4465L48.1461 50.449Z" fill="%233B3B3B"/></g><defs><filter id="filter0_d_14559_22965" x="0" y="0" width="78" height="78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14559_22965"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14559_22965" result="shape"/></filter></defs></svg>') !important;
  /* border-radius: 50%; */
  /* border: 0px solid white; */
  /* background: rgba(255, 255, 255, 0.5); */
  /*inner button fill*/
  padding: 30px;
  /*size*/
}

.carousel-control-next-icon {
  /* height: 100px; */
  /* width: 100px; */
  /* outline: rgba(255, 255, 255, 1); */
  /* background-size: 100%, 100%; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='78' height='78' viewBox='0 0 78 78' fill='none'%3E%3Cg filter='url(%23filter0_d_14567_22898)'%3E%3Ccircle cx='39' cy='35' r='34.5' transform='rotate(-180 39 35)' fill='%23DEDEDE' fill-opacity='0.3' stroke='white'/%3E%3Cpath d='M29.8539 19.551L32.7882 16.3503L50.2367 32.3261C50.5179 32.582 50.7457 32.891 50.907 33.2353C51.0683 33.5796 51.1599 33.9524 51.1766 34.3322C51.1933 34.7121 51.1346 35.0915 51.0041 35.4486C50.8736 35.8057 50.6737 36.1335 50.416 36.413L34.4346 53.8647L31.2341 50.9333L46.2338 34.5535L29.8539 19.551Z' fill='%233B3B3B'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_14567_22898' x='0' y='0' width='78' height='78' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_14567_22898'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_14567_22898' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") !important;
  /* border-radius: 50%;
  border: 2px solid black;
  background: rgba(0, 0, 0, 0.4); */
  padding: 30px;
}

.carousel-img-shrink {
  width: 100vw;
  /*width: 100%;*/
}

.carousel-xl-box-size {
  object-fit: contain;
}

.carousel-box-expand {
  flex-shrink: 0;
  min-width: 100% !important;
  min-height: 140% !important;
}

.tw-grid-row-single {
  grid-template-rows: 100% 0 0 0 0;
  overflow: hidden;
  /*overflow-x: scroll*/
}

.brand-h-8 {
  height: 2rem;
}

.brand-h-12 {
  height: 3rem;
}

.brand-w-8 {
  width: 2rem;
}

.brand-w-12 {
  width: 3rem;
}

.brand-text-justify {
  text-align: justify;
}

.brand-text-left {
  text-align: left;
}

.max-brand-label-width {
  max-width: 3rem;
}

.max-brand-label-width-lg {
  max-width: 7.36rem;
}

.min-brand-label-height {
  min-height: 3.5rem;
  margin-bottom: 0rem;
}

.max-brand-section-h-26 {
  width: 6.5rem;
}

.max-brand-section-h-46 {
  height: 11.5rem;
}

.tw-header-icon-w {
  width: 2rem;
}

.tw-header-icon-h {
  height: 2rem;
}

.tw-bigday-icon-h {
  height: 13px;
}

.tw-bigday-icon-w {
  width: 13px;
}

.tw-bigday-time {
  font-size: 0.5rem;
  line-height: 1rem;
}

.tw-bigday-title {
  font-size: 0.65rem;
  line-height: 1rem;
}

.tw-h-window-online {
  height: 600px;
}

.tw-h-window {
  height: 500px;
}

.tw-h-window-instore {
  height: 500px;
}

.tw-h-window-coupon {
  height: 500px;
}

.tw-h-window-promo {
  height: 550px;
}

.tw-py-backup {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.tw-py-backup-mobile {
  padding-top: 2rem;
  padding-bottom: 21rem;
}

@media (min-width: 768px) {
  .tw-bigday-title-lg {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .tw-h-window {
    height: 600px;
  }

  .tw-h-window-online {
    height: 600px;
  }

  .tw-h-window-instore {
    height: 450px;
  }

  .tw-h-window-coupon {
    height: 600px;
  }

  .tw-h-window-promo {
    height: 500px;
  }

  .md\:tw-w-\[65\%\] {
    width: 65%;
  }

  .md\:tw-w-\[90\%\] {
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .tw-h-window {
    height: 480px;
  }

  .tw-h-window-online {
    height: 550px;
  }

  .tw-h-window-coupon {
    height: 500px;
  }

  .tw-w-window {
    width: 80vw;
  }

  .tw-h-window-instore {
    height: 400px;
  }

  .tw-h-window-promo {
    height: 400px;
  }

  .tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .tw-py-backup {
    padding-top: 6rem;
    padding-bottom: 14em;
  }
}

@media (min-width: 1536px) {
  .tw-h-window {
    height: 375px;
  }

  .tw-h-window-online {
    height: 450px;
  }

  .tw-h-window-coupon {
    height: 650px;
  }

  .tw-h-window-instore {
    height: 300px;
  }

  .tw-h-window-promo {
    height: 330px;
  }
}

.tw-bg-surface-target {
  background-color: #BB271A;
}

.tw-bg-surface-walmart {
  background-color: #0173E5;
}

.tw-bg-surface-amazon {
  background-color: #F56600;
}

.tw-text-string-target {
  color: #BB271A;
}

.tw-text-string-walmart {
  color: #0173E5;
}

.tw-text-string-amazon {
  color: #F56600;
}

.tw-w-question-icon {
  width: 0.8rem;
}

.tw-h-question-icon {
  height: 0.8rem;
}

.tw-items-start {
  align-items: start;
}

.alert-card-button-color {
  background: rgba(13, 110, 253, 1);
}

.alert-card-img-ratio {
  height: 80%;
}

@media (min-width: 1024px) {
  .alert-card-img-ratio {
    height: 80%;
  }
}

.tw-gap-y-8 {
  row-gap: 2rem;
}

.alert-card-title {
  max-width: 40vh;
}

.alert-button-color-added {
  background-color: '#0D6EFD';
}

.alert-button-color-default {
  background-color: '#A5A5A5';
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-text-title-backup {
  color: #0D6EFD !important;
}

.tw-text-text-backup {
  color: black !important;
}

.tw-text-font-backup {
  line-height: 30.12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tw-text-detail-backup {
  font-weight: 500;
  text-align: left;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tw-pl-2 {
  padding-left: 0.5rem;
}

.tw-pl-4 {
  padding-left: 1rem;
}

.tw-mr-16 {
  margin-right: 4rem;
}

.tw-mr-1 {
  margin-right: 0.25rem;
}