.sidebar {
    position: sticky;
    top: 160px;
    bottom: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    padding-top: 0px;
    /* transition: width 0.5s; */
    /* background-color: #EDF2F7; */
}
.sidebar-buttons {
    position: sticky;
    top: 150px;
    left: 0;
    overflow: hidden;
    padding-top: 0;

}

.Link-no-change-color {
  color: inherit;
  text-decoration: inherit;
}
